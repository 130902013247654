import * as coampay from "./coampay";
import * as danal from "./danal";
import * as galaxia from "./galaxia";
import * as innocenmi from "./innocenmi";
import * as lumen from "./lumen";
import * as nanuri from "./nanuri";
import * as onnetworks from "./onnetworks";
import * as korpay from "./korpay";
import * as onflatpay from "./onflatpay";
import * as kg from "./kg";
import * as hectoFinancial from "./hecto-financial";
import * as paynb from "./paynb";
import * as paytus from "./paytus";
import * as mainpay from "./mainpay";
import * as smartro from "./smartro";
import * as wayup from "./wayup";
import * as payletter from "./payletter";
import * as toss from "./toss";

export const plugins = {
    ...coampay,
    ...danal,
    ...galaxia,
    ...innocenmi,
    ...lumen,
    ...nanuri,
    ...onnetworks,
    ...korpay,
    ...onflatpay,
    ...kg,
    ...hectoFinancial,
    ...paynb,
    ...paytus,
    ...mainpay,
    ...smartro,
    ...wayup,
    ...payletter,
    ...toss,
}
