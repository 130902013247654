import axios from "@/plugins/axios";

let url = "/api/plugins/payletter";

export const payletter = {
    request: {
        get(data){
            return axios.get(`${url}/request`, data).then(result => result.data);
        },
    }
}