import axios from "@/plugins/axios";

let url = "/api/plugins/nanuri/giftcard";

export const giftcard = {
    cp: {
        get(){
            return axios.get(`${url}/cp`).then(result => result.data);
        }
    },
    gets(data){
        return axios.get(url, data).then(result => result.data);
    },
}